import React from 'react';
import { Trans } from '@lingui/macro';

import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { CurrencyProps } from '@float/types/companyPreferences';

import * as styles from './Rate.styles.css';

type Props = {
  hourlyRate: string | null | undefined;
  currencyConfig: CurrencyProps;
};

export const Rate = (props: Props) => {
  const { hourlyRate, currencyConfig } = props;

  if (!hourlyRate) return null;

  const formattedRate = formatRateWithCurrencySymbol(
    hourlyRate,
    currencyConfig,
  );

  return (
    <span className={styles.rate}>
      <Trans>{formattedRate} /hr</Trans>
    </span>
  );
};
