import React from 'react';
import ReactDOM from 'react-dom';
import { some } from 'lodash';

import { updateMultiUserPrefs } from '@float/common/actions/currentUser';
import { updateProject } from '@float/common/actions/projects';
import { removeFromProjects } from '@float/common/components/Schedule/actions/removeFromProjects';
import {
  resetProjectPeopleSort,
  updatePersonCustomPosition,
} from '@float/common/components/Schedule/actions/updatePersonCustomPosition';
import SidebarMenu from '@float/common/components/Schedule/util/SidebarMenu';
import { Rights } from '@float/common/lib/acl';
import parse from '@float/common/lib/parse';
import { getUserAccess } from '@float/common/lib/rights';
import { capitalizeEvery } from '@float/common/lib/utils';
import { getSearchFilteredProjects } from '@float/common/search/selectors/projects';
import { selectEnhancedPersonById } from '@float/common/selectors/people/people';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { getActiveFilters } from '@float/common/selectors/views';
import { todayManager } from '@float/libs/dates';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { RowEntity } from '@float/web/components/panelViews/Project/types';
import manageModal from '@float/web/modalManager/manageModalActionCreator';
import { togglePmSidebar } from '@float/web/pmSidebar/actions';
import {
  getFullTasksMap,
  getFullTimeoffsMap,
  getPeopleMap,
  getPhaseById,
  getSearchFilteredActivePeopleMap,
  getStatuses,
  getUser,
} from '@float/web/selectors';
import {
  showPhaseSidePanel,
  showProjectSidePanel,
} from '@float/web/sidePanel/actions';
import { updatePrompts } from '@float/web/store/onboardingManager/actions';

import { findFirstTaskablePerson } from './helpers/findFirstTaskablePerson';
import { getDefaultPersonForTaskModal } from './helpers/getDefaultPersonForTaskModal';
import { getDefaultPersonForTimeoffModal } from './helpers/getDefaultPersonForTimeoffModal';

export default function createNonScheduleActions(props) {
  const {
    getCurrentRows,
    store,
    setExpandedProjectIds,
    animations,
    confirm,
    actionMode,
    setPortalContents,
    undoTentativeChanges,
    showSnackbar,
    closeSnackbar,
    history,
  } = props;

  return {
    removeFromProjects: removeFromProjects(props),
    updatePersonCustomPosition: updatePersonCustomPosition(props),
    resetProjectPeopleSort: resetProjectPeopleSort(props),

    updatePrefs(...args) {
      store.dispatch(updateMultiUserPrefs(...args));
    },

    updateProject(...args) {
      store.dispatch(updateProject(...args));
    },

    markPromptSeen(promptKey) {
      store.dispatch(updatePrompts(promptKey));
    },

    showSidebarMenu(positionRef, options) {
      const portal = document.getElementById('serena-portal');
      const position = positionRef.current.getBoundingClientRect();

      const height = options.length * 40;
      let { top } = position;
      if (top + height > window.innerHeight) {
        top = window.innerHeight - height - 5;
      }

      portal.style.top = `${top}px`;
      portal.style.left = `${position.left}px`;

      setPortalContents(
        ReactDOM.createPortal(
          <SidebarMenu
            options={options}
            close={() => setPortalContents(null)}
          />,
          portal,
        ),
      );
    },

    showProjectModal(project, defaultTab) {
      store.dispatch(
        showProjectSidePanel({
          projectId: project.project_id,
          openSections: defaultTab ? [defaultTab] : [],
          // Can be expanded to other sections, but this helper only calls 'team' in the legacy section of the code
          entityToAdd: defaultTab === 'team' ? RowEntity.Team : undefined,
          hideDelete: true,
          hideTasksTab: false,
        }),
      );
    },

    showSingleProjectView(projectId) {
      if (featureFlags.isFeatureEnabled(FeatureFlag.SingleProjectView)) {
        history.push(`/project/${projectId}`);
      }
    },

    showEditPhaseModal(project, phase, logTimeView) {
      const canEdit = project.canEdit && !logTimeView;
      const isAdding = !phase || !phase.phase_id;

      if (
        featureFlags.isFeatureEnabled(FeatureFlag.PhaseSidePanel) &&
        canEdit
      ) {
        const panelPayload = {
          phaseId: phase?.phase_id,
          projectId: project?.project_id,
        };
        if (isAdding && phase?.start_date && phase?.end_date) {
          panelPayload.startDate = phase.start_date;
          panelPayload.endDate = phase.end_date;
        }
        store.dispatch(showPhaseSidePanel(panelPayload));
        return;
      }

      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'phaseModal',
          modalSettings: {
            phase: phase || {},
            projectId: project.project_id,
            isAdding,
            editing: canEdit,
          },
        }),
      );
    },

    showProjectShiftModal(project) {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'projectShiftModal',
          modalSettings: {
            project,
            isEditable: project.canEdit,
          },
        }),
      );
    },

    showProjectDuplicateModal(project) {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'projectModal',
          modalSettings: {
            project,
            isAdding: true,
            duplicating: true,
          },
        }),
      );
    },

    showPersonModal(person) {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'personModal',
          modalSettings: {
            person,
            isEditable: person.canEdit,
          },
        }),
      );
    },

    showAddTaskModal(person, opts = {}) {
      if (!opts.start_date) opts.start_date = todayManager.getToday();
      if (!opts.end_date) opts.end_date = todayManager.getToday();

      const currentUser = getUser(store.getState());

      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'taskModal',
          modalSettings: {
            isNewTask: true,
            mode: actionMode,
            taskOrTimeoff: parse.getTaskModel(
              person ??
                getDefaultPersonForTaskModal(getCurrentRows(), {
                  allPeople: getPeopleMap(store.getState()),
                  people: getSearchFilteredActivePeopleMap(store.getState()),
                  user: currentUser,
                  filters: getActiveFilters(store.getState()),
                }),
              currentUser,
              opts,
            ),
          },
        }),
      );
    },

    showAddTimeoffModal(person, opts = {}) {
      if (!opts.start_date) opts.start_date = todayManager.getToday();
      if (!opts.end_date) opts.end_date = todayManager.getToday();

      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'taskModal',
          modalSettings: {
            mode: actionMode,
            isNewTask: true,
            taskMode: 'timeoff',
            taskOrTimeoff: parse.getTaskModel(
              person ??
                getDefaultPersonForTimeoffModal(getCurrentRows(), {
                  allPeople: getPeopleMap(store.getState()),
                  people: getSearchFilteredActivePeopleMap(store.getState()),
                  user: getUser(store.getState()),
                  filters: getActiveFilters(store.getState()),
                }),
              getUser(store.getState()),
              opts,
            ),
          },
        }),
      );
    },

    showAddProjectModal(evt) {
      evt.preventDefault();
      const user = getUser(store.getState());
      if (
        Rights.canViewProjectTemplate(user) &&
        !user.prefs?.project_from_scratch
      ) {
        store.dispatch(
          manageModal({
            visible: true,
            modalType: 'ModalProjectFromTemplate',
            modalSettings: {
              project: {},
              isAdding: true,
              editing: true,
            },
          }),
        );
        return;
      }

      store.dispatch(showProjectSidePanel());
    },

    showAddPersonModal(evt) {
      evt.preventDefault();
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'personModal',
          modalSettings: {
            person: {},
            isAdding: true,
            editing: true,
          },
        }),
      );
    },

    showEditTaskModalForId(itemId, type = 'task') {
      let task;
      const isStatus = type === 'status';
      const isTimeoff = type === 'timeoff';

      if (type === 'task') {
        task = getFullTasksMap(store.getState())[itemId] || {
          task_id: itemId,
        };
      } else if (isTimeoff) {
        task = getFullTimeoffsMap(store.getState())[itemId] || {
          timeoff_id: itemId,
        };
      } else if (isStatus) {
        task = getStatuses(store.getState())[itemId] || {
          status_id: itemId,
        };
      }

      const project = selectEnhancedProjectById(
        store.getState(),
        task.project_id,
      );

      const model = {
        ...task,
        isTimeoff,
        isStatus,
        project,
      };

      this.showEditTaskModal(model);
    },

    showEditTaskModal(model) {
      const entityId = model.task_id || model.timeoff_id || model.status_id;
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'taskModal',
          modalSettings: {
            taskOrTimeoff: model,
            isNewTask: !entityId,
          },
        }),
      );
    },

    showEmailNotificationsModal() {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'emailScheduleModal',
        }),
      );
    },

    showLogTimeModal(item) {
      const currentUser = getUser(store.getState());
      const perms = getUserAccess(currentUser);
      const editingPersonId = item.entity.people_id;
      const meFilter = currentUser.prefs?.me_filter;

      const editingPerson = selectEnhancedPersonById(
        store.getState(),
        editingPersonId,
      );
      const selfPerson = (meFilter || perms.isMember()) && currentUser;
      const person =
        editingPerson ||
        selfPerson ||
        findFirstTaskablePerson(getCurrentRows(), {
          people: getSearchFilteredActivePeopleMap(store.getState()),
          user: currentUser,
        });

      if (!person) {
        return confirm({
          title: `Can't log time`,
          message: (
            <p>
              Could not find a person to add a log for, try removing a search
              filter.
            </p>
          ),
          confirmLabel: 'OK',
          hideCancel: true,
        });
      }

      const editingProjectId = item.entity.project_id;
      const editingPhaseId = item.entity.phase_id;
      const project = selectEnhancedProjectById(
        store.getState(),
        editingProjectId,
      );
      const phase = getPhaseById(store.getState(), editingPhaseId);
      const isProjectArchived = project && !project.active;
      const isPhaseArchived = phase && !phase.active;
      const isArchived = isPhaseArchived || isProjectArchived;

      const forceReadOnly = !Rights.canUpdateLoggedTime(currentUser, {
        entity: item.entity,
        cell: item,
        person,
        project,
        phase,
      });

      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'logTimeModal',
          modalSettings: {
            entity: item.entity,
            person: person,
            readOnly: forceReadOnly || isArchived,
          },
        }),
      );
    },

    showExportCSV() {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'scheduleCSVExportModal',
        }),
      );
    },

    showNotificationsModal() {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'notificationSettingsModal',
        }),
      );
    },

    showShareLinkModal() {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'shareLinkModal',
        }),
      );
    },

    showPmSidebar() {
      store.dispatch(togglePmSidebar(true));
    },

    hideTaskModal() {
      undoTentativeChanges();
      store.dispatch(
        manageModal({
          visible: false,
          modalType: 'taskModal',
        }),
      );
    },

    toggleCollapsedProject(projectId) {
      animations.disable();

      setExpandedProjectIds((prev) => {
        if (projectId) {
          return {
            ...prev,
            [projectId]: !prev[projectId],
          };
        }

        const isCustomized = some(prev, (val) => val);
        if (isCustomized) {
          return {};
        }

        return getSearchFilteredProjects(store.getState())
          .filter((p) => p.active)
          .map((p) => p.project_id)
          .reduce((acc, id) => {
            acc[id] = true;
            return acc;
          }, {});
      });

      setTimeout(animations.enable, 1000);
    },

    showEditMilestoneModal(milestone, settings = {}) {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'milestoneModal',
          modalSettings: {
            milestone,
            ...settings,
          },
        }),
      );
    },

    showOverScheduledModal(isTimeoff, cb) {
      const message = isTimeoff
        ? "You're attempting to schedule time off for more than the person's available work hours on at least one of the selected days. Please reduce the hours and try again."
        : "You're attempting to schedule this person for more than 24 hours on at least one of the selected days. Please select another person or reduce the hours.";

      confirm({
        title: isTimeoff ? 'Available work hours limit' : '24 hour limit',
        hideCancel: true,
        onConfirm: cb,
        onCancel: cb,
        message,
      });
    },

    showFullDayTimeoffOverlapModal(cb) {
      const message =
        'An all day time off already exists on at least one of the selected days. Please review the days selected and try again.';

      confirm({
        title: 'Time off already scheduled',
        hideCancel: true,
        onConfirm: cb,
        onCancel: cb,
        message,
      });
    },

    showInsertRepeatOverlapModal() {
      confirm({
        title: `Can't Update All Allocations`,
        hideCancel: true,
        message: `You're attempting to insert an allocation between one or more allocations that are part of a repeating set. These allocations have not been updated.`,
      });
    },

    showReplaceRepeatOverlapModal() {
      confirm({
        title: `Can't Update All Allocations`,
        hideCancel: true,
        message: `You're attempting to replace one or more allocations that are part of a repeating set. These allocations have not been updated.`,
      });
    },

    showInsertMultiAssignOverlapModal() {
      confirm({
        title: `Can't Update All Allocations`,
        hideCancel: true,
        message: `You're attempting to insert an allocation between one or more allocations that are assigned to multiple people. These allocations have not been updated.`,
      });
    },

    showReplaceMultiAssignOverlapModal() {
      confirm({
        title: `Can't Update All Allocations`,
        hideCancel: true,
        message: `You're attempting to replace one or more allocations that are assigned to multiple people. These allocations have not been updated.`,
      });
    },

    showDurationLimitModal(isTimeoff, isRepeatError, limit, cb) {
      const type = isTimeoff ? 'time off' : 'allocation';
      const indefiteArticle = isTimeoff ? 'a' : 'an';
      const title = `${capitalizeEvery(type)} Too Long`;
      const message = `The maximum length ${indefiteArticle} ${type} can be is ${limit} days. Please reduce the ${
        isRepeatError ? 'repeat ' : ''
      }end date.`;
      confirm({
        title,
        hideCancel: true,
        onConfirm: cb,
        onCancel: cb,
        message,
      });
    },

    showRepeatOverlapModal(cb) {
      const message = `Sorry, you cannot have overlapping repeating allocations. Please reduce the allocation duration and try again.`;
      confirm({
        title: `Repeating Allocation Overlap`,
        hideCancel: true,
        onConfirm: cb,
        onCancel: cb,
        message,
      });
    },

    showSingleDaySplitModal() {
      confirm({
        title: `Can't Split Single Day Allocation`,
        hideCancel: true,
        message: `You're attempting to split a single day allocation. Allocations must cover at least two days in order to be split. This allocation has not been updated.`,
      });
    },

    showLoader(msg = 'Loading', id = 'serenaSnackbar') {
      return showSnackbar(msg, {
        id,
        loader: true,
        persist: true,
      });
    },

    hideLoader(id = 'serenaSnackbar') {
      closeSnackbar(id);
    },

    showSwitchPersonModal(modalSettings) {
      store.dispatch(
        manageModal({
          visible: true,
          modalType: 'ModalSwitchPerson',
          modalSettings,
        }),
      );
    },
  };
}
