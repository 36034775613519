import { MouseEventHandler } from 'react';

type Timer = ReturnType<typeof setTimeout>;

export const delayedSingleClick = (callback: MouseEventHandler) => {
  let details = 0;
  let timeout: Timer;
  const handler = (e: React.MouseEvent) => {
    details++;
    if (details === 1) {
      timeout = setTimeout(() => {
        callback(e);
        details = 0;
      }, 250);
    } else {
      clearTimeout(timeout);
      details = 0;
    }
  };
  return handler;
};
