import React, { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import cs from 'classnames';

import { keyBy } from '@float/common/lib/keyBy';
import { formatRateWithCurrencySymbol } from '@float/common/lib/rates/rates';
import { getCurrencyProps } from '@float/common/selectors/currentUser';
import { RoleOption } from '@float/common/selectors/roles';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';

import { useRoleOptionsWithRateElements } from './hooks/useRoleOptionsWithRateElements';

import * as styles from './PersonRole.css';

export type PersonRoleProps = {
  currencyConfig: ReturnType<typeof getCurrencyProps>;
  hasCustomRate: boolean;
  onRoleChange: (roleId: number | null) => void;
  placeholder?: string;
  roleId: number | null;
  rolesOptions: RoleOption[];
  showRates: boolean;
};

export const PersonRole = (props: PersonRoleProps) => {
  const {
    currencyConfig,
    hasCustomRate,
    onRoleChange,
    placeholder,
    roleId,
    rolesOptions,
    showRates,
  } = props;

  const rolesOptionsById = useMemo(
    () => keyBy(rolesOptions, 'value'),
    [rolesOptions],
  );

  const rolesOptionsWithRatesElements: VirtualSelectOption[] =
    useRoleOptionsWithRateElements({ rolesOptions, currencyConfig, showRates });

  const roleOptionSelected =
    roleId && rolesOptionsById[roleId] ? rolesOptionsById[roleId] : null;

  const formattedRate = formatRateWithCurrencySymbol(
    roleOptionSelected?.defaultHourlyRate,
    currencyConfig,
  );

  const selectedRoleRateElement =
    showRates && roleOptionSelected && roleOptionSelected.defaultHourlyRate ? (
      <span
        className={cs(
          styles.roleRateSelected,
          hasCustomRate && styles.roleRateSelectedInactive,
        )}
      >
        <Trans>{formattedRate} /hr</Trans>
      </span>
    ) : null;

  const onChange = (option: RoleOption) => {
    onRoleChange(option.value);
  };

  return (
    <div className={styles.wrapper}>
      <VirtualSelect
        // @ts-expect-error virtual select is not typed
        clearInputOnDropdownOpen={false}
        creatable
        data-testid="personRole"
        hideSelectedIcon={true}
        label={t`Role`}
        onChange={onChange}
        options={rolesOptionsWithRatesElements}
        placeholder={placeholder}
        suffix={selectedRoleRateElement}
        value={roleId}
        visibleItems={6}
      />
    </div>
  );
};
