import React from 'react';
import { BillableVsNonBillable } from 'reports/components/SummaryBarParts';

import { getPercentage } from '@float/common/lib/budget';
import { LIGHT_PURPLE, PURPLE } from '@float/ui/deprecated/Chart';
import { ChartComparisonModes } from '@float/ui/deprecated/Chart/types';

import { ReportsTotalsContainer } from '../../styles';

function SummaryBar({ totals, mode, minWidth, timeTrackingEnabled }) {
  const loggedTotal = totals.logged.total;
  const loggedBillable = totals.logged.billable;
  const loggedBillablePerc = getPercentage(
    totals.logged.billable,
    totals.logged.total,
  );
  const loggedNonBillable = totals.logged.nonbillable;
  const loggedNonBillablePerc = getPercentage(
    totals.logged.nonbillable,
    totals.logged.total,
  );

  const scheduledTotals =
    mode === ChartComparisonModes.COMBINED ? totals.future : totals;

  const scheduledTotal = scheduledTotals.scheduled;
  const scheduledBillable = scheduledTotals.billable;
  const scheduledBillablePerc = getPercentage(
    scheduledTotals.billable,
    scheduledTotals.scheduled,
  );
  const scheduledNonBillable = scheduledTotals.nonbillable;
  const scheduledNonBillablePerc = getPercentage(
    scheduledTotals.nonbillable,
    scheduledTotals.scheduled,
  );
  const scheduledTentative =
    scheduledTotals.tentative.billable + scheduledTotals.tentative.nonbillable;
  const scheduledTentativePerc = getPercentage(
    scheduledTotals.tentative.billable + scheduledTotals.tentative.nonbillable,
    scheduledTotals.scheduled,
  );

  return (
    <ReportsTotalsContainer style={{ minWidth }}>
      <BillableVsNonBillable
        title="Scheduled"
        total={scheduledTotal}
        billable={scheduledBillable}
        billablePerc={scheduledBillablePerc}
        nonBillable={scheduledNonBillable}
        nonBillablePerc={scheduledNonBillablePerc}
        tentative={scheduledTentative}
        tentativePerc={scheduledTentativePerc}
        bottomMargin={!scheduledTentative}
        noBorderRight={!timeTrackingEnabled}
      />
      {!!timeTrackingEnabled && (
        <BillableVsNonBillable
          title="Logged"
          colors={[PURPLE, LIGHT_PURPLE]}
          total={loggedTotal}
          billable={loggedBillable}
          billablePerc={loggedBillablePerc}
          nonBillable={loggedNonBillable}
          nonBillablePerc={loggedNonBillablePerc}
          bottomMargin
          noBorderRight
        />
      )}
    </ReportsTotalsContainer>
  );
}

export default SummaryBar;
