import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, isEmpty, isEqual, omit } from 'lodash';
import { getCurrentPlan, getVisibleSettings } from 'selectors';

import { ensureDepartmentsLoaded } from '@float/common/actions/departments';
import { ensureHolidaysLoaded } from '@float/common/actions/holidays';
import { ensureMilestonesLoaded } from '@float/common/actions/milestones';
import { fetchOneOffs } from '@float/common/actions/oneOffs';
import { ensurePeopleLoaded } from '@float/common/actions/people';
import { ensureContextLoaded } from '@float/common/actions/search';
import { fetchStatuses } from '@float/common/actions/statuses';
import { ensureStatusTypesLoaded } from '@float/common/actions/statusTypes';
import { fetchTasksWithDates } from '@float/common/actions/tasks';
import { fetchTimeoffsWithDates } from '@float/common/actions/timeoffs';
import { ensureTimeoffTypesLoaded } from '@float/common/actions/timeoffTypes';
import {
  getCompanyPrefs,
  isPaidPlan as isPaidPlanSelector,
  isPlusPackEnabled,
  isTimeTrackingEnabled,
} from '@float/common/selectors/companyPrefs';
import { getAllProjectsOptions } from '@float/common/selectors/projects';
import { useAppDispatch, useAppSelector } from '@float/common/store';
import { moment } from '@float/libs/moment';
import { Button } from '@float/ui/deprecated/Button/Button';
import IconExport from '@float/ui/deprecated/Earhart/Icons/Icon/IconExport';

import {
  isPlanEnterprise,
  isPlanPro,
  isPlanStarter,
} from '../Billing/TeamAccountFeaturesNew/helpers';
import Body from '../Body';
import { SUPPORT_ARTICLES } from '../constants';
import { PlanUpsellBanner } from '../PlanUpsellBanner';
import RestrictedMessage from '../RestrictedMessage';
import { DateRangeFilter } from './DateRangeFilter';
import Dropdown from './Dropdown';
import { exportCsv } from './exportCsv';
import { getAccountOptions, getPeopleOptions } from './selectors';
import { CustomButton, DropdownFilters, MainFilters } from './styles';
import { ActivityTable } from './Table';
import { useTeamActivityDateRange } from './useTeamActivityDateRange';

const FilterButton = ({ displayFilter, ...others }) => (
  <Button {...others} {...(displayFilter && { appearance: 'secondary' })}>
    {!displayFilter ? 'Filter activity' : 'Close'}
  </Button>
);

const actionOptions = [
  { value: 'create', label: 'Created' },
  { value: 'update', label: 'Updated' },
  { value: 'delete', label: 'Deleted' },
];

function isFilterEmpty(val) {
  return !val || val === 'all';
}

function TeamActivity() {
  const plan = useAppSelector(getCurrentPlan);
  const isPaidPlan = useAppSelector(isPaidPlanSelector);
  const hasPlusPack = useAppSelector(isPlusPackEnabled);
  const companyPrefs = useAppSelector(getCompanyPrefs);
  const hasTimeTracking = useAppSelector(isTimeTrackingEnabled);
  const hasBothAddons = hasPlusPack && hasTimeTracking;
  const { plan_id: planId, is_monthly: isMonthly } = plan;

  const isEnterprisePlan = isPlanEnterprise(
    planId,
    isPaidPlan,
    hasBothAddons,
    !isMonthly,
  );
  const isProPlan = isPlanPro(
    planId,
    isPaidPlan,
    companyPrefs.time_tracking,
    companyPrefs.plus_pack,
  );
  const isStarterPlan = isPlanStarter(
    planId,
    isPaidPlan,
    companyPrefs.time_tracking,
    companyPrefs.plus_pack,
  );

  const isFilterEnabled = !isStarterPlan;

  const getSubHeaderMessage = () => {
    if (isEnterprisePlan) {
      return "View all of your team's activity or filter by project or person.";
    }
    if (isProPlan) {
      return "View all of your team's activity over the last 180 days.";
    }
    return "View all of your team's activity over the last 7 days.";
  };

  const { rangeFilter, onDateRangeChange } = useTeamActivityDateRange({
    isStarterPlan,
    isProPlan,
  });

  const [filterData, setFilterData] = useState(false);
  const [projectFilter, setProjectFilter] = useState('all');
  const [personFilter, setPersonFilter] = useState('all');
  const [actionFilter, setActionFilter] = useState('all');
  const [actionedByFilter, setActionedByFilter] = useState('all');
  const [filters, setFilters] = useState({});
  const [filterLabels, setFilterLabels] = useState({});
  const [exporting, setExporting] = useState(false);

  const dispatch = useAppDispatch();
  const firstOfWeek = useAppSelector((state) =>
    get(state, 'currentUser.start_work_week'),
  );
  const projectOptions = useAppSelector(getAllProjectsOptions);
  const peopleOptions = useAppSelector(getPeopleOptions);
  const accountOptions = useAppSelector(getAccountOptions);
  const userCanSee = useAppSelector(getVisibleSettings);

  // ensure all required data is loaded
  useEffect(() => {
    dispatch(ensureContextLoaded());
    dispatch(ensureDepartmentsLoaded());
    dispatch(ensureTimeoffTypesLoaded());
    dispatch(ensureStatusTypesLoaded());
    dispatch(ensurePeopleLoaded());
    dispatch(ensureHolidaysLoaded());
    dispatch(ensureMilestonesLoaded());
  }, [dispatch]);
  useEffect(() => {
    const start = moment(rangeFilter.start)
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    const end = moment(rangeFilter.end).add(1, 'year').format('YYYY-MM-DD');
    dispatch(fetchTasksWithDates(start, end));
    dispatch(fetchTimeoffsWithDates(start, end));
    dispatch(fetchOneOffs(start, end));
    dispatch(fetchStatuses(start, end));
  }, [dispatch, rangeFilter]);

  // update filters on change
  useEffect(() => {
    const newFilters = {
      start: rangeFilter.start,
      end: rangeFilter.end,
    };
    if (filterData) {
      Object.assign(newFilters, {
        ...(personFilter !== 'all' && { 'people-ids': personFilter }),
        ...(projectFilter !== 'all' && { 'project-ids': projectFilter }),
        ...(actionFilter !== 'all' && { 'activity-types': actionFilter }),
        ...(actionedByFilter !== 'all' && {
          'actioned-by-ids': actionedByFilter,
        }),
      });
    }
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  }, [
    filters,
    filterData,
    rangeFilter,
    projectFilter,
    personFilter,
    actionFilter,
    actionedByFilter,
  ]);

  const onFilterLabelChange = (e, key) => {
    const value = get(e, 'value', 'all');
    if (isFilterEmpty(value)) {
      setFilterLabels(omit(filterLabels, key));
    } else {
      const label = e.selectedLabel || e.label;
      if (label) setFilterLabels({ ...filterLabels, [key]: label });
    }
  };

  const onProjectFilterChange = (e) => {
    setProjectFilter(get(e, 'value', 'all'));
    onFilterLabelChange(e, 'Project');
  };

  const onPersonFilterChange = (e) => {
    setPersonFilter(get(e, 'value', 'all'));
    onFilterLabelChange(e, 'Person');
  };

  const onActionFilterChange = (e) => {
    setActionFilter(get(e, 'value', 'all'));
    onFilterLabelChange(e, 'Action');
  };

  const onActionedByFilterChange = (e) => {
    setActionedByFilter(get(e, 'value', 'all'));
    onFilterLabelChange(e, 'Actioned by');
  };

  const clearFilters = useCallback(() => {
    setProjectFilter('all');
    setPersonFilter('all');
    setActionFilter('all');
    setActionedByFilter('all');
    setFilterLabels({});
  }, []);

  const getExportMetadata = useCallback(() => {
    return {
      dateRange: { start: rangeFilter.start, end: rangeFilter.end },
      filters: isEmpty(filterLabels) ? null : filterLabels,
    };
  }, [filterLabels, rangeFilter]);

  const onExportReady = useCallback(
    async (data) => {
      await exportCsv(data, companyPrefs.company_name, getExportMetadata());
      setExporting(false);
    },
    [getExportMetadata, companyPrefs.company_name],
  );

  const hasActivityFilter = useMemo(() => {
    return !(
      isFilterEmpty(projectFilter) &&
      isFilterEmpty(personFilter) &&
      isFilterEmpty(actionFilter) &&
      isFilterEmpty(actionedByFilter)
    );
  }, [actionFilter, actionedByFilter, personFilter, projectFilter]);

  const exportButton = useMemo(() => {
    if (!isFilterEnabled) return null;

    return (
      <CustomButton
        appearance="ghost"
        size="small"
        iconRight={exporting ? null : IconExport}
        loader={exporting}
        onClick={() => setExporting(true)}
        floatRight
      >
        Export
      </CustomButton>
    );
  }, [exporting, isFilterEnabled]);

  const planUpsellBanner = useMemo(
    () => (
      <PlanUpsellBanner
        header="Need longer activity feed?"
        subheader="Get 180 days with our Pro plan."
        learnMoreUrl={SUPPORT_ARTICLES.activityFeed}
      />
    ),
    [],
  );

  if (!userCanSee.teamActivity) {
    return <RestrictedMessage />;
  }

  return (
    <Body
      header="Activity"
      subheader={getSubHeaderMessage()}
      headerWidget={exportButton}
      withPlusPackUpgrade={!hasPlusPack}
      planUpsellBanner={planUpsellBanner}
    >
      {isFilterEnabled && (
        <>
          <MainFilters>
            <DateRangeFilter
              rangeMode={rangeFilter.rangeMode}
              firstOfWeek={firstOfWeek}
              start={rangeFilter.start}
              end={rangeFilter.end}
              onChange={onDateRangeChange}
            />

            <FilterButton
              displayFilter={filterData}
              onClick={() => setFilterData(!filterData)}
            />
          </MainFilters>

          <DropdownFilters hide={!filterData}>
            <Dropdown
              placeholder="All projects"
              value={projectFilter}
              groupedOptions={projectOptions}
              onChange={onProjectFilterChange}
            />
            <Dropdown
              placeholder="All people"
              value={personFilter}
              groupedOptions={peopleOptions}
              onChange={onPersonFilterChange}
            />
            <Dropdown
              placeholder="All actions"
              value={actionFilter}
              options={actionOptions}
              onChange={onActionFilterChange}
            />
            <Dropdown
              placeholder="All actioned by"
              value={actionedByFilter}
              options={accountOptions}
              onChange={onActionedByFilterChange}
            />
            <Button
              size="large"
              onClick={clearFilters}
              disabled={!hasActivityFilter}
            >
              Clear
            </Button>
          </DropdownFilters>
        </>
      )}

      <ActivityTable
        filters={filters}
        exporting={exporting}
        onExportReady={onExportReady}
      />
    </Body>
  );
}

export default TeamActivity;
