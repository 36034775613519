import BG from '@float/common/components/Schedule/util/bgimage';

export const getGridBackgroundSize = (dayWidth: number, numDays: number) => {
  if ([27, 90, 245].includes(dayWidth)) {
    return `${dayWidth * numDays}px ${dayWidth * numDays}px`;
  }
  return `${dayWidth}px ${dayWidth}px`;
};

export const getGridBackgroundUrl = (
  dayWidth: number,
  numDays: number,
): string => {
  if ([27, 90, 245].includes(dayWidth)) {
    // @ts-expect-error type isn't supported properly in bgimage
    return `url(${BG[`${dayWidth}x${numDays}`]})`;
  }
  return 'linear-gradient(90deg, #edebf0 1px, #ffffff 1px, #ffffff)';
};
