import React from 'react';
import { Trans } from '@lingui/macro';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { Project } from '@float/types/project';

import * as projectCardStyles from './ProjectCard.css';
import * as styles from './SingleProjectHeader.css';

export const SingleProjectHeaders = ({ project }: { project: Project }) => {
  const projectColor = project.color.startsWith('#')
    ? project.color
    : `#${project.color}`;
  return (
    <div className={projectCardStyles.wrapper} data-interactive={true}>
      <div
        className={styles.content}
        style={assignInlineVars({
          [styles.contentBgColorVar]: projectColor,
        })}
      >
        <div>
          <Trans>Team member</Trans>
        </div>
        <div>
          <Trans>Role</Trans>
        </div>
        <div>
          <Trans>Cost rate</Trans>
        </div>
        <div>
          <Trans>Bill rate</Trans>
        </div>
        <div>
          <Trans>Allocation</Trans>
        </div>
      </div>
    </div>
  );
};
