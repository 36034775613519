import React from 'react';
import { t } from '@lingui/macro';
import { isEmpty, lowerCase, sortBy } from 'lodash';

import AvatarImage from '@float/common/components/Avatar/AvatarImage';
import { getCanCurrentUserSeeBudgets } from '@float/common/lib/acl/getCanCurrentUserSeeBudgets';
import { formatAmount } from '@float/common/lib/budget';
import { NON_MONETARY_BUDGET_TYPES } from '@float/constants/budgets';
import { noop } from '@float/libs/utils/noop';
import { Account, CurrentUser } from '@float/types/account';
import { Client } from '@float/types/client';
import { Milestone } from '@float/types/milestone';
import { Person } from '@float/types/person';
import { Project } from '@float/types/project';
import { Tag } from '@float/types/tag';
import { RichText } from '@float/ui/deprecated/Earhart/RichText';
import { ReadOnlyField } from '@float/ui/deprecated/Field/ReadOnlyField';
import { ToggleButton } from '@float/ui/deprecated/Toggle/ToggleButton';
import { ToggleGroup } from '@float/ui/deprecated/Toggle/ToggleGroup';
import { DEFAULT_TAG_COLOR } from '@float/web/selectors/tags';

import { Task } from './ProjectForm/components/Task';
import {
  budgetOptions,
  BudgetRow,
  rateOptions,
  ToggleGroupWrapper,
} from './ProjectForm/InfoFragment';
import {
  ScrollableList as MSScrollableList,
  NoMilestones,
} from './ProjectForm/MilestonesFragment.styles.js';
import {
  NoTaskNames,
  ScrollableList as TaskScrollableList,
} from './ProjectForm/TasksFragment.styles';
import {
  NoTeam,
  ScrollableList as TeamScrollableList,
} from './ProjectForm/TeamFragment.styles.js';
import {
  formatMilestoneDate,
  getFilteredTeam,
  getPersonProjectRate,
  getSortedMilestones,
} from './ProjectModal.helpers';

const { Item, ActivityCircle } = Task._styles;

type ProjectDetailsInfoProps = {
  project: Project;
  clients: Client[];
  currentUser: CurrentUser;
  budgetLabel: string;
  projectTagByLabel: Record<string, Tag>;
};

const ProjectDetailsInfo = (props: ProjectDetailsInfoProps) => {
  const { project, clients, currentUser, budgetLabel, projectTagByLabel } =
    props;

  let _budgetLabel;
  const budgetType = project.budget_type || 0;
  const renderRateRow = [2, 3].includes(budgetType);
  const budgetRateType = project.default_hourly_rate === null ? 0 : 1;
  const canSeeBudgets = getCanCurrentUserSeeBudgets(currentUser);
  const renderBudgets =
    canSeeBudgets || NON_MONETARY_BUDGET_TYPES.includes(budgetType);

  if (renderBudgets) {
    _budgetLabel =
      budgetLabel ||
      budgetOptions.find(
        (option) =>
          option.budget_type === budgetType &&
          option.budget_priority === project.budget_priority,
      )?.label;
  }

  return (
    <>
      {project.project_code && (
        <ReadOnlyField
          label={t`Project code`}
          type="text"
          value={project.project_code}
          wrapperStyle={{ marginBottom: 21 }}
        />
      )}

      <ReadOnlyField label={t`Status`} type="status" value={project.status} />

      {project.client_id && (
        <ReadOnlyField
          label={t`Client`}
          type="text"
          value={clients[project.client_id].client_name}
          wrapperStyle={{ marginBottom: 21 }}
        />
      )}

      <ReadOnlyField
        label={t`Color`}
        type="color"
        value={project.color}
        wrapperStyle={{ marginBottom: 21 }}
      />

      {project.description && (
        <RichText
          label={t`Notes`}
          value={project.description}
          valueMeta={project.notes_meta}
          readOnly
          mentionsEnabled
          style={{ marginBottom: 22 }}
          onChange={noop}
        />
      )}

      {!isEmpty(project.tags) && (
        <ReadOnlyField
          label={t`Tags`}
          type="tags"
          wrapperStyle={{ marginBottom: 21 }}
          value={project.tags.map((tag) => {
            const color = projectTagByLabel[tag]?.color
              ? `#${projectTagByLabel[tag].color}`
              : DEFAULT_TAG_COLOR;

            return {
              name: tag,
              type: 1,
              color,
            };
          })}
        />
      )}

      <ToggleGroupWrapper>
        <ToggleGroup type="multiple" value={['billable', 'tentative']} readOnly>
          <ToggleButton value="billable">
            {project.non_billable ? t`Non-billable` : t`Billable`}
          </ToggleButton>

          {project.tentative && (
            <ToggleButton value="tentative">{t`Tentative`}</ToggleButton>
          )}
        </ToggleGroup>
      </ToggleGroupWrapper>

      {renderBudgets && (
        <>
          <BudgetRow style={{ marginBottom: 22 }}>
            <ReadOnlyField label="Budget" type="text" value={_budgetLabel} />

            {budgetType === 1 && (
              <ReadOnlyField
                label={t`Hours`}
                type="text"
                value={project.budget_total}
                wrapperStyle={{ marginLeft: 'auto', width: 120 }}
              />
            )}

            {budgetType === 2 && canSeeBudgets && (
              <ReadOnlyField
                label={t`Total`}
                type="text"
                value={formatAmount(budgetType, project.budget_total, {
                  inLocale: true,
                })}
                currentUser={currentUser}
                wrapperStyle={{ marginLeft: 'auto', width: 120 }}
              />
            )}
          </BudgetRow>

          {!!canSeeBudgets && renderRateRow && (
            <BudgetRow style={{ marginBottom: 22 }}>
              <ReadOnlyField
                label={t`Rate`}
                type="text"
                value={rateOptions[budgetRateType].label}
              />

              {budgetRateType === 1 && (
                <ReadOnlyField
                  label={t`Per Hour`}
                  type="text"
                  value={formatAmount(budgetType, project.default_hourly_rate, {
                    inLocale: true,
                  })}
                  wrapperStyle={{ marginLeft: 'auto', width: 120 }}
                />
              )}
            </BudgetRow>
          )}
        </>
      )}
    </>
  );
};

type ProjectOwnerReadOnlyProps = {
  project: Project;
  accounts: Account[];
  managerAccountId: number;
};
export function ProjectOwnerReadOnly({
  project,
  accounts,
  managerAccountId,
}: ProjectOwnerReadOnlyProps) {
  const manager = project.project_manager
    ? accounts[project.project_manager]
    : accounts[managerAccountId];

  // This could happen if current user is limited by department,
  // and manager is from another department.
  if (!manager) return null;

  return (
    <ReadOnlyField
      label={t`Project Owner`}
      type="text"
      value={
        <div style={{ display: 'flex' }}>
          <AvatarImage
            name={manager.name}
            imageUrl={manager.avatar}
            style={{ marginRight: 10 }}
          />
          {manager ? manager.name : ''}
        </div>
      }
      wrapperStyle={{ marginBottom: 21 }}
    />
  );
}

type ProjectDetailsTeamProps = {
  project: Project;
  people: Person;
  currentUser: CurrentUser;
  accounts: Account[];
  isPhase: boolean;
  managerAccountId: number;
};
const ProjectDetailsTeam = ({
  project,
  people,
  currentUser,
  accounts,
  isPhase,
  managerAccountId,
}: ProjectDetailsTeamProps) => {
  const filteredPeople = getFilteredTeam(project, currentUser, people);
  const canSeeBudgets = getCanCurrentUserSeeBudgets(currentUser);

  const isVariableRate =
    (project.budget_type === 2 || project.budget_type === 3) &&
    project.default_hourly_rate === null;

  return (
    <>
      <TeamScrollableList>
        {filteredPeople.map((person, i) => (
          <li key={person.people_id}>
            <span className="avatar-image">
              <AvatarImage name={person.name} imageUrl={person.avatar_file} />
            </span>
            <span className="name">{person.name}</span>
            <div className="controls">
              {canSeeBudgets && isVariableRate ? (
                <>
                  <span>{getPersonProjectRate(person, project)}</span>
                  <span className="per-hour">p/hr</span>
                </>
              ) : null}
            </div>
          </li>
        ))}
        {isEmpty(filteredPeople) && (
          <NoTeam>
            {isPhase
              ? t`There are no people for this phase.`
              : t`There are no people for this project.`}
          </NoTeam>
        )}
      </TeamScrollableList>
      <ProjectOwnerReadOnly
        project={project}
        accounts={accounts}
        managerAccountId={managerAccountId}
      />
    </>
  );
};

type ProjectDetailsMilestonesProps = {
  milestones: Milestone[];
  isPhase: boolean;
};
const ProjectDetailsMilestones = ({
  milestones,
  isPhase,
}: ProjectDetailsMilestonesProps) => {
  return (
    <MSScrollableList>
      {getSortedMilestones(milestones).map((milestone) => {
        return (
          <li key={milestone.milestone_id}>
            <div className="text-content">
              <span className="name">{milestone.name}</span>
              <br />
              <span className="date">{formatMilestoneDate(milestone)}</span>
            </div>
          </li>
        );
      })}

      {isEmpty(milestones) && (
        <NoMilestones>
          {isPhase
            ? t`There are no milestones for this phase.`
            : t`There are no milestones for this project.`}
        </NoMilestones>
      )}
    </MSScrollableList>
  );
};

type ProjectDetailsTasks = {
  project: Project;
  taskNames: { task_name: string; billable: number; count: number }[];
  isPhase: boolean;
};
const ProjectDetailsTasks = ({
  project,
  taskNames,
  isPhase,
}: ProjectDetailsTasks) => {
  return (
    <TaskScrollableList>
      {sortBy(taskNames, (t) => lowerCase(t.task_name)).map((task) => {
        const projectBillable = !project.non_billable;
        const showBillableLabel = projectBillable && task.billable;
        const name = task.task_name || t`No Task Name`;

        return (
          <Item key={name}>
            <div className="name">
              {task.count > 0 && <ActivityCircle />}
              {name}
            </div>
            <span className="billable-label">
              {showBillableLabel && t`Billable`}
            </span>
          </Item>
        );
      })}

      {isEmpty(taskNames) && (
        <NoTaskNames>
          {isPhase
            ? t`There are no task names for this phase.`
            : t`There are no task names for this project.`}
        </NoTaskNames>
      )}
    </TaskScrollableList>
  );
};

type ProjectDetailsProps = {
  activeTab: string;
} & ProjectDetailsInfoProps &
  ProjectDetailsTeamProps &
  ProjectDetailsMilestonesProps &
  ProjectDetailsTasks;
export default ({ activeTab, ...rest }: ProjectDetailsProps) => {
  return (
    <div style={{ marginBottom: 20 }}>
      {activeTab === 'info' && <ProjectDetailsInfo {...rest} />}
      {activeTab === 'team' && <ProjectDetailsTeam {...rest} />}
      {activeTab === 'milestones' && <ProjectDetailsMilestones {...rest} />}
      {activeTab === 'tasks' && <ProjectDetailsTasks {...rest} />}
    </div>
  );
};
