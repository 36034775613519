import React from 'react';
import { t } from '@lingui/macro';

import { Button } from '@float/ui/components/Button';
import { IconSpv } from '@float/ui/icons/essentials/IconSpv';

import { useOpenSPVFromSidePanel } from './ProjectActionsMenu/hooks/useOpenSPVFromSidePanel';

export const OpenSPVButton = () => {
  const onOpenSPVClick = useOpenSPVFromSidePanel();
  return (
    <Button
      size="xsmall"
      appearance="clear-flay"
      iconOnlyLabel={t`Open project view`}
      iconEnd={IconSpv}
      onClick={onOpenSPVClick}
    />
  );
};
