import React from 'react';

import { CurrencyProps } from '@float/types/companyPreferences';
import { Role } from '@float/types/role';

import { Rate } from '../Rate/Rate';

import * as styles from './PersonRoleRate.styles.css';

export type PersonRoleRate = {
  name: string;
  hourlyRate: Role['default_hourly_rate'];
  currencyConfig: CurrencyProps;
};

export const PersonRoleRate = (props: PersonRoleRate) => {
  const { currencyConfig, name, hourlyRate } = props;

  console.log(hourlyRate);

  if (!hourlyRate) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.rate}>
        <span className={styles.name}>{name}</span>
        <Rate currencyConfig={currencyConfig} hourlyRate={hourlyRate} />
      </div>
    </div>
  );
};
