import { Project } from '@float/types/project';

export const getPlanLink = (
  isProjectPlanInNav: boolean,
  isSPVEnabled: boolean,
  projectName: string,
  projectId: Project['project_id'],
) => {
  const projectPlanLink = `/${
    isProjectPlanInNav ? 'project-plan' : ''
  }?project=${encodeURIComponent(projectName)}`;
  const planLink = isSPVEnabled ? `/project/${projectId}` : projectPlanLink;

  return planLink;
};
