import { t } from '@lingui/macro';
import { map } from 'lodash';
import {
  avatarFormatter,
  filterLinkFormatter,
  pctFormatter,
} from 'reports/helpers/tableFormatters';

import { getPercentage } from '@float/common/lib/budget';
import { AccordionTableData } from '@float/ui/deprecated/AccordionTable/types';

import { getEmptyTaskLabel } from './getEmptyTaskLabel';
import {
  TeamCapacityReportsProject,
  TeamCapacityTableContext,
  TeamCapacityTableDataRaw,
} from './types';

function breakdown(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { projects, isSinglePersonView } = ctx;
  const byProject: Record<number, TeamCapacityReportsProject> = {};

  raw.totals.forEach((item) => {
    if (!item.project_id) return;
    if (!projects[item.project_id]) {
      console.error('No project found', { item });
      return null;
    }

    if (!byProject[item.project_id]) {
      byProject[item.project_id] = {
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        future: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
          overtime: 0,
        },
        timeoff: 0,
        overtime: 0,
        children: {},
      };
    }

    const record = byProject[item.project_id];

    // We want to aggregate the children by person in multi-person mode but
    // by task in single-person mode
    const key = isSinglePersonView
      ? item.name || getEmptyTaskLabel()
      : item.person_id;
    if (!key) {
      console.error(item);
      throw Error('Expected item.person_id');
    }

    if (!record?.children?.[key]) {
      record.children![key] = {
        key,
        scheduled: 0,
        billable: 0,
        nonbillable: 0,
        logged: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        combined: {
          scheduled: 0,
          billable: 0,
          nonbillable: 0,
        },
        timeoff: 0,
      };
    }

    const child = record.children![key];

    // Record refers to the parent row (people).
    // Child refers to the child row (project/timeoff).
    // The "root" suffix is used to get either the task/logged time data
    if (item.type === 'task' || item.type === 'logged_time') {
      if (item.type === 'logged_time' && item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      const childRoot = item.type === 'task' ? child : child.logged;
      const recordRoot = item.type === 'task' ? record : record.logged;

      childRoot.scheduled += item.hours.scheduled;
      recordRoot.scheduled += item.hours.scheduled;
      record.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;
      record.future!.scheduled += item.hours.future;
      child.combined.scheduled +=
        item.type === 'task' ? item.hours.future : item.hours.scheduled;

      if (item.billable) {
        childRoot.billable += item.hours.scheduled;
        recordRoot.billable += item.hours.scheduled;
        record.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future!.billable += item.hours.future;
        child.combined.billable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
      } else {
        childRoot.nonbillable += item.hours.scheduled;
        recordRoot.nonbillable += item.hours.scheduled;
        record.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
        record.future!.nonbillable += item.hours.future;
        child.combined.nonbillable +=
          item.type === 'task' ? item.hours.future : item.hours.scheduled;
      }
    }
  });

  return byProject;
}

export function getScheduledTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, projects, isSinglePersonView } = ctx;

  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 60,
      formatter: avatarFormatter,
      allowOverflow: true,
    },
    { label: t`Scheduled`, width: 100 },
    { label: t`Billable`, width: 100 },
    { label: t`Non-billable`, width: 100 },
    {
      label: t`Billable %`,
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (ctx.isProjectCodesEnabled) {
    headers.splice(1, 0, {
      label: t`Project code`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw) return { headers, rows: [] };

  const byProject: Record<number, TeamCapacityReportsProject> = breakdown(
    ctx,
    raw,
  );

  const rows = map(byProject, (o, projectId) => {
    const managerId = projects[projectId].project_manager;
    const manager = ctx.accounts[managerId];

    const projectName = `project::${projects[projectId].project_name}`;
    const projectClient =
      projects[projectId].client_name === 'No Client'
        ? 'No Client'
        : `client::${projects[projectId].client_name}`;
    const projectManagerAvatar = `project-report-manager-avatar::${projects[projectId].project_manager}::${manager?.name}`;
    const projectCode = projects[projectId].project_code || '-';

    const data = [
      projectName,
      projectClient,
      projectManagerAvatar,
      o.scheduled,
      o.billable,
      o.nonbillable,
      getPercentage(o.billable, o.scheduled),
    ];

    if (ctx.isProjectCodesEnabled) {
      data.splice(1, 0, projectCode);
    }

    return {
      id: projectId,
      data,
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key!].name;

        return {
          data: [
            name,
            '',
            '',
            // If project codes are enabled, add an empty column to match with total row columns accordingly.
            ...(ctx.isProjectCodesEnabled ? [''] : []),
            c.scheduled,
            c.billable,
            c.nonbillable,
            getPercentage(c.billable, c.scheduled as number),
          ],
        };
      }),
    };
  });

  return { headers, rows };
}

export function getLoggedTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, projects, isSinglePersonView } = ctx;

  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 60,
      formatter: avatarFormatter,
      allowOverflow: true,
    },
    { label: t`Logged`, width: 100 },
    { label: t`Billable`, width: 100 },
    { label: t`Non-billable`, width: 100 },
    {
      label: t`Billable %`,
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (ctx.isProjectCodesEnabled) {
    headers.splice(1, 0, {
      label: t`Project code`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw) return { headers, rows: [] };

  const byProject = breakdown(ctx, raw);

  const rows = map(byProject, (o, projectId) => {
    const managerId = projects[projectId].project_manager;
    const manager = ctx.accounts[managerId];

    const projectName = `project::${projects[projectId].project_name}`;
    const projectClient =
      projects[projectId].client_name === 'No Client'
        ? 'No Client'
        : `client::${projects[projectId].client_name}`;
    const projectManagerAvatar = `project-report-manager-avatar::${projects[projectId].project_manager}::${manager?.name}`;
    const projectCode = projects[projectId].project_code || '-';

    const data = [
      projectName,
      projectClient,
      projectManagerAvatar,
      o.logged.scheduled,
      o.logged.billable,
      o.logged.nonbillable,
      getPercentage(o.logged.billable, o.logged.scheduled),
    ];

    if (ctx.isProjectCodesEnabled) {
      data.splice(1, 0, projectCode);
    }

    return {
      id: projectId,
      data,
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key!].name;

        return {
          data: [
            name,
            '',
            '',
            // If project codes are enabled, add an empty column to match with total row columns accordingly.
            ...(ctx.isProjectCodesEnabled ? [''] : []),
            c.logged.scheduled,
            c.logged.billable,
            c.logged.nonbillable,
            getPercentage(c.logged.billable, c.logged.scheduled),
          ],
        };
      }),
    };
  }).filter(Boolean);

  return { headers, rows };
}

export function getCompareTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, projects, isSinglePersonView } = ctx;

  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 60,
      formatter: avatarFormatter,
      allowOverflow: true,
    },
    { label: t`Scheduled`, width: 200 },
    { label: t`Logged`, width: 200 },
    { label: t`Difference`, width: 200 },
  ];

  if (ctx.isProjectCodesEnabled) {
    headers.splice(1, 0, {
      label: t`Project code`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw) return { headers, rows: [] };

  const byProject = breakdown(ctx, raw);

  const rows = map(byProject, (o, projectId) => {
    const managerId = projects[projectId].project_manager;
    const manager = ctx.accounts[managerId];

    const projectName = `project::${projects[projectId].project_name}`;
    const projectClient =
      projects[projectId].client_name === 'No Client'
        ? 'No Client'
        : `client::${projects[projectId].client_name}`;
    const projectManagerAvatar = `project-report-manager-avatar::${projects[projectId].project_manager}::${manager?.name}`;
    const projectCode = projects[projectId].project_code || '-';

    const data = [
      projectName,
      projectClient,
      projectManagerAvatar,
      o.scheduled,
      o.logged.scheduled,
      o.scheduled - o.logged.scheduled,
    ];

    if (ctx.isProjectCodesEnabled) {
      data.splice(1, 0, projectCode);
    }

    return {
      id: projectId,
      data,
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key!].name;

        return {
          data: [
            name,
            '',
            '',
            // If project codes are enabled, add an empty column to match with total row columns accordingly.
            ...(ctx.isProjectCodesEnabled ? [''] : []),
            c.scheduled,
            c.logged.scheduled,
            (c.scheduled as unknown as number) - c.logged.scheduled,
          ],
        };
      }).filter(Boolean),
    };
  });

  return { headers, rows };
}

export function getCombinedTable(
  ctx: TeamCapacityTableContext,
  raw: TeamCapacityTableDataRaw,
) {
  const { people, projects, isSinglePersonView } = ctx;

  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Client`,
      align: 'flex-start',
      grow: 1,
      formatter: filterLinkFormatter,
    },
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 60,
      formatter: avatarFormatter,
      allowOverflow: true,
    },
    { label: t`Past logged + Future sched.`, width: 200 },
    { label: t`Billable`, width: 100 },
    { label: t`Non-billable`, width: 100 },
    {
      label: t`Billable %`,
      width: 120,
      isPercent: true,
      formatter: pctFormatter,
    },
  ];

  if (ctx.isProjectCodesEnabled) {
    headers.splice(1, 0, {
      label: t`Project code`,
      align: 'flex-start',
      grow: 1,
    });
  }

  if (!raw) return { headers, rows: [] };

  const byProject = breakdown(ctx, raw);

  const rows = map(byProject, (o, projectId) => {
    const managerId = projects[projectId].project_manager;
    const manager = ctx.accounts[managerId];

    const projectName = `project::${projects[projectId].project_name}`;
    const projectClient =
      projects[projectId].client_name === 'No Client'
        ? 'No Client'
        : `client::${projects[projectId].client_name}`;
    const projectManagerAvatar = `project-report-manager-avatar::${projects[projectId].project_manager}::${manager?.name}`;
    const projectCode = projects[projectId].project_code || '-';

    const data = [
      projectName,
      projectClient,
      projectManagerAvatar,
      o.combined.scheduled,
      o.combined.billable,
      o.combined.nonbillable,
      getPercentage(o.combined.billable, o.combined.scheduled),
    ];

    if (ctx.isProjectCodesEnabled) {
      data.splice(1, 0, projectCode);
    }

    return {
      id: projectId,
      data,
      children: map(o.children, (c) => {
        const name = isSinglePersonView ? c.key : people[c.key!].name;

        return {
          data: [
            name,
            '',
            '',
            // If project codes are enabled, add an empty column to match with total row columns accordingly.
            ...(ctx.isProjectCodesEnabled ? [''] : []),
            c.combined.scheduled,
            c.combined.billable,
            c.combined.nonbillable,
            getPercentage(c.combined.billable, c.combined.scheduled),
          ],
        };
      }),
    };
  });

  return { headers, rows };
}
