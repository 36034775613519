import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { Role } from '@float/types';

import { ReduxStateStrict } from '../reducers/lib/types';

export const getRoles = (state: ReduxStateStrict): Record<string, Role> =>
  state.roles.roles;

export type RoleHourlyRate = {
  costRate: Role['default_hourly_rate'];
  billRate: Role['default_hourly_rate'];
};

export type RoleOption = {
  label: Role['name'];
  value: Role['id'];
  defaultHourlyRate?: Role['default_hourly_rate'];
  hourlyRates?: RoleHourlyRate;
};

export const getRolesOptions = createSelector(
  [getRoles],
  (roles: Record<number, Role>): RoleOption[] => {
    const results = Object.values(roles).map((role) => ({
      value: role.id,
      label: role.name,
      defaultHourlyRate: role.default_hourly_rate,
    }));

    return sortBy(results, (role) => role.label.toLowerCase());
  },
);
