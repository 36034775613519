import React from 'react';

import { CurrencyProps } from '@float/types/companyPreferences';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';
import { RoleOption } from '@float/web/selectors';

import { PersonRoleRates } from '../components/PersonRoleRates/PersonRoleRates';
import { Rate } from '../components/Rate/Rate';

export type UseRoleOptionsWithRateElements = {
  rolesOptions: RoleOption[];
  currencyConfig: CurrencyProps;
  showRates: boolean;
};
export const useRoleOptionsWithRateElements = (
  props: UseRoleOptionsWithRateElements,
) => {
  const { currencyConfig, rolesOptions, showRates } = props;

  return rolesOptions?.map((role): VirtualSelectOption => {
    const option: VirtualSelectOption = {
      label: role.label,
      value: role.value,
    };

    if (showRates) {
      if (role.hourlyRates) {
        option.children = (
          <PersonRoleRates
            currencyConfig={currencyConfig}
            rates={role.hourlyRates}
          />
        );
      } else if (role.defaultHourlyRate) {
        option.iconRight = (
          <Rate
            hourlyRate={role.defaultHourlyRate}
            currencyConfig={currencyConfig}
          />
        );
      }
    }

    return option;
  });
};
