import React from 'react';
import { t } from '@lingui/macro';

import { CurrencyProps } from '@float/types/companyPreferences';
import { RoleHourlyRate } from '@float/web/selectors';

import { PersonRoleRate } from '../PersonRoleRate/PersonRoleRate';

import * as styles from './PersonRoleRates.styles.css';

export type PersonRoleRates = {
  rates: RoleHourlyRate;
  currencyConfig: CurrencyProps;
};

export const PersonRoleRates = (props: PersonRoleRates) => {
  const { rates, currencyConfig } = props;

  if (!rates.costRate || !rates.billRate) return null;

  return (
    <div className={styles.wrapper}>
      <PersonRoleRate
        name={t`Cost rate`}
        hourlyRate={rates.costRate}
        currencyConfig={currencyConfig}
      />

      <PersonRoleRate
        name={t`Bill rate`}
        hourlyRate={rates.billRate}
        currencyConfig={currencyConfig}
      />
    </div>
  );
};
