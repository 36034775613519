import { t } from '@lingui/macro';
import { map } from 'lodash';

import { getDoesBudgetUseFees } from '@float/common/lib/acl/getDoesBudgetUseFees';
import { formatAmount } from '@float/common/lib/budget';
import { Phase } from '@float/types/phase';
import { BudgetType } from '@float/types/project';
import { AccordionTableRowConfig } from '@float/ui/deprecated/AccordionTable/types';
import { ProjectPercentageModes } from '@float/ui/deprecated/Chart/types';

import { SimplifiedReportsProject } from '../types';
import {
  getBudgetDataObject,
  getFeeOrHoursAsPercentageOfBudget,
} from './budget';
import { getRangeBar } from './getRangeBar';

export const getPhaseScheduleFee = ({
  canSeeFees,
  phaseSummary,
  isBillableProject,
}: {
  canSeeFees: boolean;
  phaseSummary: SimplifiedReportsProject;
  isBillableProject: boolean;
}) => {
  if (!canSeeFees) return 0;

  const fee = isBillableProject
    ? phaseSummary.scheduled.billableFee
    : phaseSummary.scheduled.fee;

  return formatAmount(phaseSummary.budget_type, fee);
};

export function getPhaseRowsForProject(
  phaseSummaries: Record<number, SimplifiedReportsProject>,
  phases: Record<number, Phase | undefined>,
  {
    budgetType,
    canSeeFees,
    hasBudgetPerPhase,
    isBillableProject,
    isTimeTrackingEnabled,
    isProjectCodesEnabled,
    percentageMode,
  }: {
    budgetType: number | null | undefined;
    canSeeFees: boolean;
    hasBudgetPerPhase?: boolean;
    isBillableProject: boolean;
    isTimeTrackingEnabled: number | boolean;
    isProjectCodesEnabled?: boolean;
    percentageMode: ProjectPercentageModes;
  },
): AccordionTableRowConfig[] {
  // @TODO(PI-91)
  // @ts-expect-error - We need to refactor the types to account for the RangeBar types
  return map(phaseSummaries, (phaseSummary, phaseId) => {
    const phase = phases[Number(phaseId)];
    const ProjectHasHourlyFeeBudget = budgetType === BudgetType.HourlyFee;
    const ProjectHasFeeByPhaseBudget =
      budgetType === BudgetType.TotalFee && hasBudgetPerPhase;
    const ProjectHasHoursByPhaseBudget =
      budgetType === BudgetType.TotalHours && hasBudgetPerPhase;

    const showBudgetColumn =
      ProjectHasHourlyFeeBudget ||
      (ProjectHasHoursByPhaseBudget && phase) ||
      (ProjectHasFeeByPhaseBudget && phase); // Don't on empty phases for Fee By Phase budgets

    const phaseName = phase ? phase.phase_name : t`No Phase`;
    const phaseBudget = showBudgetColumn
      ? getBudgetDataObject(phaseSummary)
      : '';
    const phaseScheduledFee = getPhaseScheduleFee({
      canSeeFees,
      phaseSummary,
      isBillableProject,
    });
    const budgetUsesFees = getDoesBudgetUseFees(budgetType);
    const phaseScheduledHours = phaseSummary.scheduled.hours;
    const phaseScheduledCell = canSeeFees
      ? t`${phaseScheduledFee} / ${phaseScheduledHours}h`
      : phaseScheduledHours;
    const phaseFeePercentage = getFeeOrHoursAsPercentageOfBudget(phaseSummary, {
      percentageMode,
      useBillable: isBillableProject,
      useFees: budgetUsesFees,
    });
    const phaseRangeBar = getRangeBar(
      percentageMode,
      phaseSummary,
      isBillableProject,
    );

    const columns = [
      phaseName,
      '', // gap
      '', // "Client" column gap
      '', // gap,
      '', // "Owner" column gap
      '', // gap
      phaseBudget,
      '',
      phaseScheduledCell,
      '',
      phaseFeePercentage,
      phaseRangeBar,
    ];

    if (isTimeTrackingEnabled) {
      // Add the logged columns if the user has time tracking
      const projectLoggedFee = canSeeFees
        ? formatAmount(phaseSummary.budget_type, phaseSummary.logged.fee)
        : '';
      const projectLoggedHours = phaseSummary.logged.hours;
      const projectLoggedHoursCell = budgetUsesFees
        ? t`${projectLoggedFee} / ${projectLoggedHours}h`
        : projectLoggedHours;

      columns.splice(10, 0, projectLoggedHoursCell, '');
    }

    if (isProjectCodesEnabled) {
      columns.splice(
        2,
        0,
        '', // "Project code" column gap
        '', // gap
      );
    }

    return { data: columns };
  });
}
