import { forEach, isUndefined } from 'lodash';

import { getCanCurrentUserSeeFeesForProject } from '@float/common/lib/acl/getCanCurrentUserSeeFees';
import { toCents } from '@float/common/lib/budget';
import { Phase } from '@float/types/phase';
import { BudgetPriority } from '@float/types/project';
import {
  AccordionTableData,
  AccordionTableRowConfig,
} from '@float/ui/deprecated/AccordionTable/types';
import { truncNumber } from '@float/ui/helpers/number/numberParser';

import { getPhaseRowsForProject } from './helpers/getPhaseRowsForProject';
import { getProjectTableRowData } from './helpers/getProjectRowData';
import { getProjectsTableHeaders } from './helpers/getProjectsRowHeaders';
import {
  ProjectsOverviewTableContext,
  ProjectsOverviewTableDataRaw,
  ReportsProject,
} from './types';

export function aggregateByProject(
  ctx: ProjectsOverviewTableContext,
  raw: ProjectsOverviewTableDataRaw | null,
) {
  const { projects, phases } = ctx;
  const byProject: Record<number, ReportsProject> = {};

  if (!raw) return {};

  function getRecord(projectId: number) {
    if (!byProject[projectId]) {
      const project = projects[projectId];

      byProject[projectId] = {
        id: projectId,
        budget: null,
        budget_type: null,
        name: project.project_name,
        project_code: project.project_code,
        scheduled: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
        logged: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
        children: {},
      };
    }

    return byProject[projectId];
  }

  function initPhaseChild(
    phase: Phase | { phase_id: number; project_id: number },
  ) {
    const c = {
      phase_id: phase.phase_id,
      budget: raw?.phases[phase.phase_id]?.budget,
      budget_type: byProject[phase.project_id].budget_type,
      scheduled: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
      logged: { fee: 0, hours: 0, billableFee: 0, billableHours: 0 },
      children: {},
    };
    if (isUndefined(c.budget)) {
      c.budget = null;
    }
    return c;
  }

  for (const _projectId in raw.budgets) {
    const projectIdForBudget = Number(_projectId);
    const budget = raw.budgets[projectIdForBudget];
    const project = projects[projectIdForBudget];

    if (!project) {
      console.error('No project found', projectIdForBudget, budget);
      return;
    }

    const record = getRecord(projectIdForBudget);
    record.budget = budget.budget;
    record.budget_type = budget.type;
  }

  forEach(phases, (phase) => {
    // Only include phases for projects returned in report-api response,
    // honoring search filters if any.
    if (phase && byProject[phase.project_id]) {
      getRecord(phase.project_id).children[phase.phase_id] =
        initPhaseChild(phase);
    }
  });

  raw.totals.forEach((item) => {
    if (!item.project_id) return;
    if (!projects[item.project_id]) {
      console.error('No project found', item);
      return;
    }

    if (!byProject[item.project_id]) {
      console.error('Expected initialized object', { item, raw });
      return;
    }

    const record = byProject[item.project_id];
    if (item.phase_id === 0 && !record.children[item.phase_id]) {
      const noPhase = { phase_id: 0, project_id: item.project_id };
      record.children[item.phase_id] = initPhaseChild(noPhase);
    }

    const child = !isUndefined(item.phase_id) && record.children[item.phase_id];

    if (item.type === 'task') {
      const key =
        ctx.loggedTimeBoundary !== '9999-01-01' ? 'future' : 'scheduled';
      record.scheduled.hours = truncNumber(
        record.scheduled.hours + item.hours[key],
      );

      const fee = toCents(item.hours[key] * item.rate);
      record.scheduled.fee = (toCents(record.scheduled.fee) + fee) / 100;
      if (item.billable) {
        record.scheduled.billableHours = truncNumber(
          record.scheduled.billableHours + item.hours[key],
        );
        record.scheduled.billableFee =
          (toCents(record.scheduled.billableFee) + fee) / 100;
      }

      if (child) {
        child.scheduled.hours = truncNumber(
          child.scheduled.hours + item.hours[key],
        );
        child.scheduled.fee = (toCents(child.scheduled.fee) + fee) / 100;

        if (item.billable) {
          child.scheduled.billableHours = truncNumber(
            child.scheduled.billableHours + item.hours[key],
          );
          child.scheduled.billableFee =
            (toCents(child.scheduled.billableFee) + fee) / 100;
        }
      }
    } else if (item.type === 'logged_time') {
      if (item.date >= ctx.loggedTimeBoundary) {
        return;
      }

      record.logged.hours = truncNumber(
        record.logged.hours + item.hours.scheduled,
      );
      const fee = toCents(item.hours.scheduled * item.rate);
      record.logged.fee = (toCents(record.logged.fee) + fee) / 100;
      if (item.billable) {
        record.logged.billableHours = truncNumber(
          record.logged.billableHours + item.hours.scheduled,
        );
        record.logged.billableFee =
          (toCents(record.logged.billableFee) + fee) / 100;
      }

      if (child) {
        child.logged.hours = truncNumber(
          child.logged.hours + item.hours.scheduled,
        );
        child.logged.fee = (toCents(child.logged.fee) + fee) / 100;
        if (item.billable) {
          child.logged.billableHours = truncNumber(
            child.logged.billableHours + item.hours.scheduled,
          );
          child.logged.billableFee =
            (toCents(child.logged.billableFee) + fee) / 100;
        }
      }
    }
  });

  return byProject;
}

export function getProjectsTable(
  ctx: ProjectsOverviewTableContext,
  rawData: ProjectsOverviewTableDataRaw | null,
): AccordionTableData {
  const { projects } = ctx;

  const headers = getProjectsTableHeaders(ctx);

  const byProject = aggregateByProject(ctx, rawData);

  const rows: AccordionTableRowConfig[] = [];
  forEach(byProject, (project, id) => {
    if (!projects[project.id]) return null;

    const isBillableProject = !projects[project.id].non_billable;
    const canSeeFees = getCanCurrentUserSeeFeesForProject(
      ctx.user,
      project.budget_type,
    );

    const data = getProjectTableRowData(ctx, projects, project);

    const phaseRows = getPhaseRowsForProject(project.children, ctx.phases, {
      budgetType: project.budget_type,
      canSeeFees,
      hasBudgetPerPhase:
        projects[project.id].budget_priority === BudgetPriority.Phase,
      isBillableProject,
      isTimeTrackingEnabled: ctx.timeTrackingEnabled,
      percentageMode: ctx.projectsPercentageMode,
      isProjectCodesEnabled: ctx.isProjectCodesEnabled,
    });

    rows.push({ id, data, children: phaseRows });
  });

  return { headers, rows };
}
