import { fetchCurrentUserAuthInfo } from '@float/common/api3/auth';
import {
  IntercomPlatform,
  requestIntercomHash,
} from '@float/common/api3/intercom';
import { requestPageData } from '@float/common/api3/pageData';
import { initIntercom } from '@float/common/lib/intercom';
import { config } from '@float/libs/config';
import { logger } from '@float/libs/logger';
import { initializeFeatureFlagsClient } from '@float/libs/web/featureFlags';

import { initPostHog } from './lib/posthog';
import { pushValueToGTM } from './lib/tracking/googleTagManager';
import { initDatadogRum } from './perfMonitoring/datadog';
import { createWebAppStore } from './reducers';
import { AUTH_ROUTES, Routes } from './router/constants';

/**
 * Get the gclid (Google Click Identifier) from the page URL, if it exists
 * so it can be used for attribution
 */
const getGclidFromPageURL = () => {
  return new URLSearchParams(window.location.search).get('gclid') ?? undefined;
};

const getIsAuthRoute = () => {
  return AUTH_ROUTES.includes(location.pathname as Routes);
};

export async function initFeatureFlags() {
  const isAuthRoute = getIsAuthRoute();
  const isSharedLinkRoute = config.isSharedView;

  if (isAuthRoute || isSharedLinkRoute) {
    await initializeFeatureFlagsClient(null, config.environment);
  } else {
    const user = await fetchCurrentUserAuthInfo();
    await initializeFeatureFlagsClient(user, config.environment);
  }
}

export async function initStoreAndThirdPartyServices() {
  initPostHog();

  const isAuthRoute = getIsAuthRoute();
  const isSharedLinkRoute = config.isSharedView;

  // Needs to be initialized after feature flags
  initDatadogRum();

  const store = createWebAppStore();

  if (!isAuthRoute && !isSharedLinkRoute) {
    const gclid = getGclidFromPageURL();
    const pageDataPromise = requestPageData(gclid);
    const hashPromise = requestIntercomHash(IntercomPlatform.Web);

    const pageData = await pageDataPromise;

    try {
      initIntercom(pageData, await hashPromise);
    } catch (e) {
      logger.error('Error initializing Intercom', e);
    }

    return { store, pageData };
  }

  pushValueToGTM({
    event: 'WebAppLoaded',
  });

  return { store, pageData: undefined };
}
