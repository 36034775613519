import {
  RowType,
  SINGLE_PROJECT_PROJECT_ROW_HEIGHT,
  SINGLE_PROJECT_USER_ROW_HEIGHT,
} from '@float/constants/schedule';
import { CellsMap } from '@float/types/cells';
import { ScheduleRow } from '@float/types/rows';

import { getProjectRowHeight } from '../../projectRow.helpers';

type CellsHeights = CellsMap['_heights'];

const MIN_ROW_HEIGHTS = { project: 2, person: 8 };

export function createRowHeightGetter({
  boundaryCol,
  cellsHeights,
  containerY,
  hourHeight,
  numVisibleWeeks,
  scrollbarSize,
  singleUserView,
  isSingleProjectView,
}: {
  boundaryCol: number;
  cellsHeights: CellsHeights;
  containerY: number;
  hourHeight: number;
  numVisibleWeeks: number;
  scrollbarSize: number;
  singleUserView: boolean;
  isSingleProjectView: boolean;
}) {
  return function getRowHeight(row: ScheduleRow) {
    if (isSingleProjectView) {
      return row.type === RowType.Project
        ? SINGLE_PROJECT_PROJECT_ROW_HEIGHT
        : SINGLE_PROJECT_USER_ROW_HEIGHT;
    }

    const bottomPadding = Math.floor(1.5 * hourHeight);
    const heights = cellsHeights[row.id] ?? {};
    let maxHeight = MIN_ROW_HEIGHTS[row.type];

    // Find the max height within the visible cols
    const start = boundaryCol - numVisibleWeeks;
    const stop = boundaryCol + numVisibleWeeks;

    for (let i = start; i <= stop; i += numVisibleWeeks) {
      if (heights[i] && heights[i].maxHeight > maxHeight) {
        maxHeight = heights[i].maxHeight;
      }
    }

    if (singleUserView) {
      const baseHeight = Math.ceil(maxHeight * hourHeight) + bottomPadding;

      return Math.max(
        baseHeight,
        window.innerHeight - containerY - 53 - (scrollbarSize || 0),
      );
    }

    if (row.type === 'project') {
      return getProjectRowHeight({
        hourHeight,
        maxCellSize: maxHeight,
        minRowSize: MIN_ROW_HEIGHTS.project,
      });
    }

    return Math.ceil(maxHeight * hourHeight) + bottomPadding;
  };
}
