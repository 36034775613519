import { t } from '@lingui/macro';
import {
  amountFormatter,
  avatarFormatter,
  filterLinkFormatter,
  hoursFormatter,
  pctFormatter,
  rangeBarFormatter,
  tooltipFormatter,
} from 'reports/helpers/tableFormatters';

import { AccordionTableData } from '@float/ui/deprecated/AccordionTable/types';

import { getPctHeader } from '../PercentageSelector';
import { ProjectsOverviewTableContext } from '../types';

export function getProjectsTableHeaders(ctx: ProjectsOverviewTableContext) {
  const COLUMN_SPACING_GAP = { label: '', width: 24 };
  const headers: AccordionTableData['headers'] = [
    {
      label: t`Project`,
      align: 'flex-start',
      width: 250,
      formatter: filterLinkFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: t`Client`,
      align: 'flex-start',
      width: 100,
      formatter: filterLinkFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: t`Owner`,
      align: 'flex-start',
      width: 60,
      allowOverflow: true,
      formatter: avatarFormatter,
    },
    { label: '', grow: 0 },
    {
      label: t`Budget`,
      grow: 1,
      allowOverflow: true,
      formatter: amountFormatter,
    },
    COLUMN_SPACING_GAP,

    {
      label: t`Scheduled`,
      grow: 1,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: t`Logged`,
      grow: 1,
      allowOverflow: true,
      formatter: hoursFormatter,
    },
    COLUMN_SPACING_GAP,
    {
      label: getPctHeader(ctx),
      width: 90,
      formatter: pctFormatter,
    },
    {
      label: '',
      width: 130,
      // @TODO(PI-91)
      // @ts-expect-error - We need to refactor the types to account for the RangeBar types
      formatter: rangeBarFormatter,
      allowOverflow: true,
    },
  ];

  if (!ctx.timeTrackingEnabled) {
    // Hide the Logged columns if the user doesn't have time tracking
    headers.splice(9, 2);
  }

  if (ctx.isProjectCodesEnabled) {
    headers.splice(
      2,
      0,
      {
        label: t`Project code`,
        align: 'flex-start',
        width: 120,
        formatter: tooltipFormatter,
      },
      COLUMN_SPACING_GAP,
    );
  }

  return headers;
}
