export enum ScheduleViewType {
  People = 'people',
  Projects = 'projects',
  SingleProject = 'singleProject',
}

export enum RowType {
  Project = 'project',
  Person = 'person',
}

export const SIDE_CELL_WIDTH = 260;
export const SIDE_CELL_WIDTH_SMALL = 64;
export const SINGLE_PROJECT_SIDE_CELL_WIDTH = 470;
export const SINGLE_PROJECT_PROJECT_ROW_HEIGHT = 32;
export const SINGLE_PROJECT_USER_ROW_HEIGHT = 36;
