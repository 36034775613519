import React from 'react';
import { Link } from 'react-router-dom';
import Linkify from 'linkify-react';
import { isNil } from 'lodash';
import { TeamCapacityTableContext } from 'reports/TeamCapacity/parser/table/types';
import styled from 'styled-components';

import { addFilters } from '@float/common/actions/search';
import { formatAmount } from '@float/common/lib/budget';
import { isEditableProject } from '@float/common/lib/utils';
import { VirtualFilterTypes } from '@float/types';
import { Tooltip } from '@float/ui/components/Tooltip';
import {
  AccordionTableCellFormatter,
  AccordionTableCellValue,
  ParsedAccordionTableCellValue,
  RangeBarFormatter,
} from '@float/ui/deprecated/AccordionTable/types';
import {
  BarConfig,
  RangeBar,
} from '@float/ui/deprecated/Chart/RangeBar/RangeBar';
import IconPencil from '@float/ui/deprecated/Icons/IconPencil';
import { TextTooltip } from '@float/ui/deprecated/Tooltip/TextTooltip';

import { ProjectManagerAvatar } from '../components/ProjectManagerAvatar';
import { ReportTimeItemData } from '../types';
import { showLogTimeEditModal } from './loggedTime';

const TableLink = styled.span`
  cursor: pointer;
  width: 100%;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    text-decoration: underline;
  }
`;

const NegativeAmt = styled.span`
  color: ${(p) => p.theme.red};
`;

export const pctFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || typeof cell === 'object' || cell === '')
    return '';
  return `${cell}%`;
};

export const amountFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'object') {
    if (typeof cell?.sortVal === 'undefined' || cell?.sortVal === '') return '';
    if (cell.val === '-') return '-';
    if (cell.type === -1) return '-';
    if (cell.type === 3) return 'Hourly fee';
    const formatted = formatAmount(cell.type, cell.val);
    return typeof cell.val === 'number' && cell?.val >= 0 ? (
      formatted ?? ''
    ) : (
      <NegativeAmt>{formatted}</NegativeAmt>
    );
  }

  return '';
};

export const feeFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || cell === '') return '';

  if (typeof cell === 'object' && cell) {
    return formatAmount(1, cell.val) ?? '';
  }

  return formatAmount(2, cell) ?? '';
};

export const hoursFormatter: AccordionTableCellFormatter = (cell) => {
  if (typeof cell === 'undefined' || cell === '') return '';

  if (typeof cell === 'object' && cell) {
    if (cell.display) {
      return cell.display;
    }

    return formatAmount(1, cell.val) ?? '';
  }

  if (typeof cell === 'string') {
    return cell;
  }

  return formatAmount(1, cell) ?? '';
};

const getCellConfig = (
  cell: AccordionTableCellValue | undefined,
): ParsedAccordionTableCellValue => {
  if (isNil(cell)) {
    return {
      val: '',
    };
  }

  if (typeof cell === 'object') {
    return cell;
  }

  if (typeof cell === 'number') {
    return {
      val: cell,
    };
  }

  if (!cell.includes('::')) {
    return {
      val: cell,
    };
  }

  const [type, val, name] = cell.split('::');
  return {
    type,
    val,
    name: name || val,
  };
};

export const avatarFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, name, val } = getCellConfig(cell);

  if (type === 'project-report-manager-avatar') {
    return <ProjectManagerAvatar id={Number(val)} name={name || 'N/A'} />;
  }

  return val;
};

// TODO: Replace with a more robust overflow detection system
// https://linear.app/float-com/issue/DQ-46/introduce-overflow-detection-for-tooltip-display-in-reports-table
const tooltipCells: Record<string, number> = {
  client: 18,
  'project-report-link': 20,
  'project-code': 12,
};
const shouldShowTooltip = (
  type: ReturnType<typeof getCellConfig>['type'],
  val: ReturnType<typeof getCellConfig>['val'],
) => {
  if (typeof type === 'string' && typeof val === 'string') {
    return val.length > tooltipCells[type];
  }

  return false;
};

export const filterLinkFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, name, val } = getCellConfig(cell);

  if (!type) {
    return val;
  }

  if (type === 'project-report-link') {
    const link = (
      <Link
        to={{
          pathname: '/report',
          search: `?project=${encodeURIComponent(name || val)}`,
        }}
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        {val}
      </Link>
    );

    if (shouldShowTooltip(type, val)) {
      return (
        <Tooltip placement="bottom" content={val}>
          {link}
        </Tooltip>
      );
    }
    return link;
  }

  const tableLink = (
    <TableLink
      onClick={(e) => {
        window.reduxStoreDispatch(
          addFilters(
            [{ type: type as VirtualFilterTypes, val: String(name || val) }],
            {
              usePushState: true,
            },
          ),
        );
      }}
    >
      {val}
    </TableLink>
  );

  if (shouldShowTooltip(type, val)) {
    return (
      <Tooltip placement="bottom" content={val}>
        {tableLink}
      </Tooltip>
    );
  }

  return tableLink;
};

export const rangeBarFormatter: RangeBarFormatter = (cell: BarConfig[]) => {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', width: 90, height: 36 }}
    >
      <div style={{ width: 90, height: 12 }}>
        <RangeBar series={cell || []} asPercent={false} />
      </div>
    </div>
  );
};

export const tooltipFormatter: AccordionTableCellFormatter = (cell) => {
  const { type, val } = getCellConfig(cell);

  if (!type) {
    <Tooltip placement="bottom" content={val}>
      <span>{val}</span>
    </Tooltip>;
  }

  if (shouldShowTooltip(type, val)) {
    return (
      <Tooltip placement="bottom" content={val}>
        <span>{val}</span>
      </Tooltip>
    );
  }

  return val;
};

const LogTimeEditContainer = styled.div`
  cursor: pointer;
  position: relative;
  min-width: 38px;
  text-align: right;

  svg {
    border-radius: 3px;
    border: 1px solid ${(p) => p.theme.medGrey};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    width: 34px;
    position: absolute;
    right: -5px;
    top: 5px;
    background: white;
    display: none;
  }

  &:hover svg {
    display: block;
  }
`;

function canEditLoggedTime(
  ctx: TeamCapacityTableContext,
  entity: ReportTimeItemData,
) {
  const project = ctx.projects[entity.project_id];
  const readOnly =
    project &&
    `${entity.person_id}` !== `${ctx.user.people_id}` &&
    (!project.active || !isEditableProject(project, ctx.user));
  return !readOnly;
}

export const logTimeEditFormatter =
  (ctx: TeamCapacityTableContext) =>
  (val?: { entity?: ReportTimeItemData; sortVal: string }) => {
    if (typeof val !== 'object') return val;
    if (!val.entity) return val.sortVal;
    if (!canEditLoggedTime(ctx, val.entity)) return val.sortVal;

    return (
      <LogTimeEditContainer
        onClick={() => {
          showLogTimeEditModal(ctx.reduxDispatch, val?.entity?.id);
        }}
      >
        {val.sortVal}
        <TextTooltip content="Edit" className="hint">
          <IconPencil color="#C8C8C8" />
        </TextTooltip>
      </LogTimeEditContainer>
    );
  };

export function getLinkifiedValue(value?: string) {
  if (!value) return '';

  return (
    <span
      style={{
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        outline: 'none',
      }}
      key="notes"
    >
      <Linkify
        options={{
          attributes: {
            contentEditable: false,
            tabIndex: -1,
          },
        }}
      >
        {value.replace(/\n/g, ' ')}
      </Linkify>
    </span>
  );
}
