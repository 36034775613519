import React, { createElement, useEffect } from 'react';
import { compact } from 'lodash';
import { StyledComponent } from 'styled-components';

import { useLinearCyclesIntegrationConfig } from '@float/common/api3/linearCyclesIntegration';
import { useViewportSettersContext } from '@float/common/components/Schedule/util/ViewportContext';
import useInfiniteSizerEffect from '@float/common/components/Schedule/Window/useInfiniteSizerEffect';
import { RowMetas } from '@float/common/serena/Data/useRowMetas.helpers';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { getSideCellWidth } from '@float/common/serena/util/getSideCellWidth';
import { ScheduleViewType } from '@float/constants/schedule';
import { todayManager } from '@float/libs/dates';
import {
  CellsMap,
  LoggedTimeRowId,
  PersonRowId,
  ProjectRowId,
} from '@float/types/cells';
import { ScheduleRowList } from '@float/types/rows';

import { MainCellProps } from '../Cell/MainCell';
import { ProjectRowStickyDates } from '../Cell/MainCell/ProjectRowDates/ProjectRowStickyDates';
import { ScheduleActions } from '../Cell/MainCell/types';
import { SideCellProps } from '../Cell/SideCell';
import { buildTopRow, BuildTopRowParams } from './buildTopRow';
import buildWindowRows, { WindowColumn } from './buildWindowRows';
import { useVisibleDateRangeOnSchedule } from './useVisibleDateRangeOnSchedule';
import { ColRange, RowRange, useWindowRanges } from './useWindowRanges';
import { EMPTY_OBJECT } from './Window.constants';
import { PinTargetRenderer, TopCellRenderer } from './Window.types';

// We render more top headers and the sticky side cells than the main cells.
// This improves visibility of headers while scrolling since more are rendered,
// and those are cheap to render compared to the main cell.
const STICKY_ROW_OVERSCAN_COUNT = 8;

export type UseWindowOpts = {
  containerWidth: number;
  containerHeight: number;
  containerY: number;
  rows: ScheduleRowList;
  rowMetas: RowMetas;
  cells: CellsMap;
  renderers: {
    placeholderRow: PinTargetRenderer;
    rowGroup: StyledComponent<'div', any, {}, never>;
    main: React.FC<MainCellProps>;
    side: false | React.FC<SideCellProps>;
    top: TopCellRenderer;
    pinTarget: (props: React.HTMLProps<HTMLDivElement>) => React.ReactNode;
  };
  renderersConfig: BuildTopRowParams['renderersConfig'];
  actions: ScheduleActions;
  helpers: {
    isColumnFetched: (id: number) => boolean;
  };
  animations: { enable: () => void; disable: () => void };
  disableTooltips: BuildTopRowParams['disableTooltips'];
  draggedRowId: string | undefined | null;
  draggedRowGroupRef: React.RefObject<HTMLDivElement | null>;
  onVisibleRangeChange?: (value: ColRange & RowRange) => void;
  onScroll?: (x: number, y: number) => void;
  linkedArrowTargetRef: React.RefObject<SVGSVGElement | null>;
};

export type VisibleWindowRange = ColRange & RowRange;

export function useWindow(opts: UseWindowOpts) {
  const {
    containerWidth,
    containerHeight,
    containerY,
    rows,
    rowMetas,
    cells,
    renderers,
    renderersConfig,
    actions,
    helpers,
    animations,
    disableTooltips,
    draggedRowId,
    draggedRowGroupRef,
    onVisibleRangeChange,
    onScroll,
    linkedArrowTargetRef,
  } = opts;

  const {
    baseColOffset,
    dates,
    dayWidth,
    hourHeight,
    logMyTimeView,
    logTimeViewType,
    numCols,
    numDays,
    printMode,
    scrollbarSize,
    scrollWrapperRef,
    setBaseColOffset,
    setNumCols,
    singleUserView,
    suvPersonId,
    timeRange,
    timeRangeSettings,
    viewType,
    suvWeek,
  } = useScheduleContext();

  const { setBoundaryCol, setHeightsUpdatedAt } = useViewportSettersContext();

  const { linearCyclesIntegrationConfig } = useLinearCyclesIntegrationConfig();
  const isSingleProjectView = viewType === ScheduleViewType.SingleProject;
  const colWidth = numDays * dayWidth;
  const totalWidth = colWidth * numCols;
  const numVisibleWeeks = singleUserView
    ? 1
    : Math.max(2, Math.ceil(containerWidth / colWidth));

  const { colRange, rowRange, rowsPositionManager, boundaryCol } =
    useWindowRanges({
      scrollWrapperRef,
      colWidth,
      containerWidth,
      baseColOffset,
      numCols,
      onVisibleRangeChange,
      onScroll,
      hourHeight,
      containerHeight,
      containerY,
      scrollbarSize,
      rows,
      cells,
      numVisibleWeeks,
      viewType,
      singleUserView,
      animations,
      suvWeek,
    });

  useEffect(() => {
    setBoundaryCol(boundaryCol);
  }, [setBoundaryCol, boundaryCol]);

  useEffect(() => {
    setHeightsUpdatedAt(cells._heightsUpdatedAt);
  }, [cells._heightsUpdatedAt, setHeightsUpdatedAt]);

  // Expand the schedule left and right as the user scrolls near the edges
  useInfiniteSizerEffect({
    containerWidth,
    colWidth,
    scrollWrapperRef,
    numCols,
    setNumCols,
    baseColOffset,
    setBaseColOffset,
    logMyTimeView,
  });

  const visibleDateRange = useVisibleDateRangeOnSchedule({
    disabled: viewType !== 'projects',
    margin: 80,
    leftStickyBoundary: getSideCellWidth(isSingleProjectView),
  });

  const scrollToDate = React.useCallback(
    (dateStr: string) => {
      const [colIdx] = dates.toDescriptor(dateStr);
      actions.scrollToCol(colIdx);
    },
    [dates, actions],
  );

  const { bounds, totalHeight } = rowsPositionManager;
  const { colStart, colStop } = colRange;
  let { rowStart, rowStop } = rowRange;
  if (printMode) {
    rowStart = 0;
    rowStop = bounds.length;
  }

  const rowGroups: React.ReactNode[] = [];
  const side: React.ReactNode[] = [];

  function buildRow(
    rowIdx: number,
    columns: WindowColumn[],
    isDragging?: boolean,
  ) {
    const boundary = bounds[rowIdx];
    const row = rows[rowIdx];
    const translateY = isDragging ? 0 : boundary.offset;

    const colIdx = dates.getCurrentWeek();
    const cellKey = `${rows[rowIdx].id}:${colIdx}` as const;
    const currentWeekCell = cells[cellKey];
    const dayOfWeek = dates.toDayOfWeek(todayManager.getToday());
    const isProjectRow = row.type === 'project';

    const children = compact([
      createElement(renderers.pinTarget, {
        key: 'pin-target',
        className: 'pin-target',
      }),
      isProjectRow && (
        <ProjectRowStickyDates
          {...visibleDateRange}
          key="project-sticky-dates"
          startDate={row.data.start_date}
          endDate={row.data.end_date}
          hourHeight={hourHeight}
          scrollToDate={scrollToDate}
        />
      ),
      renderers.side &&
        createElement(renderers.side, {
          key: `side-${rowIdx}`,
          row,
          style: {
            // To force the left column to always have a grey background, even
            // if we're scrolling quickly, we make the height of each side cell
            // equal to the total container height minus its start offset.
            height: isDragging
              ? boundary.size
              : Math.max(totalHeight - boundary.offset, boundary.size),
          },
          rowHeight: boundary.size,
          rowGroupTop: boundary.offset,
          hourHeight,
          actions,
          currentWeekCell,
          dayOfWeek,
          logTimeView: logTimeViewType,
          timeRangeSettings,
          viewType,
        }),
    ]);

    function getMainElementProps({
      key,
      colIdx,
    }: {
      key: PersonRowId | LoggedTimeRowId | ProjectRowId;
      colIdx: number;
    }): MainCellProps {
      const style = {
        left: colWidth * (colIdx - baseColOffset),
        width: colWidth,
        overflow: '',
      };

      if (isProjectRow) {
        style.overflow = 'visible';
      }

      return {
        key,
        style,
        rowIdx,
        colIdx,
        row,
        rowMeta: rowMetas.get(row.id),
        cell: cells[key] || EMPTY_OBJECT,
        dayWidth,
        numDays,
        hourHeight,
        viewType,
        actions,
        isColumnFetched: helpers.isColumnFetched(colIdx),
        rowGroupTop: translateY,
        'data-floatid': key,
        linkedArrowTargetRef,
      };
    }

    for (const column of columns) {
      children.push(createElement(renderers.main, getMainElementProps(column)));
    }

    const key = 'key' in row ? row.key : row.id;

    // Project rows don't need the +1 adjustment
    // https://linear.app/float-com/issue/PRJ-876/vqa-double-stroke-on-the-rows
    const rowGroupHeight = isProjectRow ? boundary.size : boundary.size + 1;

    rowGroups.push(
      // eslint-disable-next-line react/no-children-prop
      createElement(renderers.rowGroup, {
        ref: isDragging ? draggedRowGroupRef : undefined,
        key,
        rowType: row.type,
        style: {
          transform: isDragging ? '' : `translate(0, ${translateY}px)`,
          height: rowGroupHeight,
          width: totalWidth,
          zIndex: isDragging ? 999 : rowIdx,
        },
        darkBackground: row.darkBackground,
        dayWidth,
        numDays,
        'data-floatid': key,
        'data-translate-y': translateY,
        isDragging,
        isAnyRowDragging: !!draggedRowId,
        children,
      }),
    );

    if (isDragging) {
      rowGroups.push(
        createElement(renderers.placeholderRow, {
          key: `placeholder-${key}`,
          style: {
            transform: `translate(0, ${translateY}px)`,
            height: boundary.size + 1,
            width: totalWidth,
          },
        }),
      );
    }
  }

  // Build out the row groups
  if (bounds.length) {
    const rowGroups = buildWindowRows({
      cells,
      colStart,
      colStop,
      overscan: STICKY_ROW_OVERSCAN_COUNT,
      rowStart,
      rowStop,
      rows,
      draggedRowId,
    });

    for (const { rowIdx, columns, isDragging } of rowGroups.data) {
      buildRow(rowIdx, columns, isDragging);
    }
  }

  const topRow = buildTopRow({
    actions,
    baseColOffset,
    bounds,
    cells,
    colStart,
    colStop,
    colWidth,
    dates,
    dayWidth,
    disableTooltips,
    numCols,
    renderers,
    renderersConfig,
    logMyTimeView,
    scrollWrapperRef,
    showCycles: linearCyclesIntegrationConfig?.active,
    suvPersonId,
    timeRange,
    totalHeight,
    isSingleProjectView,
  });

  return {
    elements: {
      top: topRow,
      side,
      main: rowGroups,
    },
    totalHeight: totalHeight - 2,
    totalWidth,
    rowsPositionManager,
  };
}
